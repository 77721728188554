import React from "react"
import { graphql, Link } from "gatsby"
import { rhythm } from '../utils/typography'
import Containers from "../components/container"
import Header from "../components/header"
import Headertop from "../components/headertop"
import Footer from "../components/footer"
import Formfooter from "../components/form-footer"
import { Container, Col, Row } from "react-bootstrap"
import * as blogStyles from "../css/blog.module.css"
import * as homeStyles from "../css/index.module.css"
import { Icon } from '@iconify/react';
import arrowRight from '@iconify/icons-akar-icons/arrow-right';
import chevronDoubleRight from '@iconify/icons-mdi-light/chevron-double-right';
import Img from "gatsby-image"
import Bgblog from "../components/bgblog"
import Seo from "../components/seo"

const Seotitle = "บทความที่น่าสนใจ"
const SeoDecription = "บทความ เกี่ยวกับเส้นด้าย ที่เป็นแรงบันดาลใจ ในการให้บริการ เรามีความมุ่งมั่นที่จะส่งต่อข้อมูลข่าวสารและองค์ความรู้เกี่ยวกับเส้นด้าย"

export default class BlogList extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "/blog/" : "/blog/" + (currentPage - 1).toString()
    const nextPage = "/blog/" + (currentPage + 1).toString()
    const seoImage = ""
    const url = "/blog/"
    return (
        <Containers>
          <Seo 
           title={Seotitle}
           description={SeoDecription}
           image={seoImage}
           pathname={url}
           />
            <Headertop />
            <Header />
            <Bgblog />
            <div className={blogStyles.herob}>
                <Container fluid="xl">
                    <Row>
                        <Col><p><Link to="/">Home</Link>  <Icon icon={chevronDoubleRight} />  Blog</p></Col>
                    </Row>
                    <Row>
                        <Col className="py-3">
                          <p>บทความที่คุณไม่ควรพลาด เปิดโลกของความรู้และแรงบันดาลใจ เมื่อคุณมองหาบทความที่จะอ่าน ไม่ว่าคุณจะเป็นนักอ่านที่หาความรู้ นักเรียนที่กำลังค้นหาข้อมูล หรือแค่ผู้ที่ต้องการความบันเทิงที่มีคุณค่า บทความของเราเสมอนำเสนอประสบการณ์ที่เต็มไปด้วยความรู้และแรงบันดาลใจ นี่คือบทความที่คุณไม่ควรพลาด</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={blogStyles.herosection}>
                <Container fluid="xl">
                    <Row>{posts.map(({ node }) => {
                        const title = node.frontmatter.title || node.fields.slug
                        const description = node.frontmatter.description || node.fields.slug
                        const link = node.fields.slug
                        const image = node.frontmatter.featuredImage.childImageSharp.fluid
                        return <Col md={4} key={node.fields.slug}>
                                    <Link to={link}>
                                    <Img fluid={image} className={homeStyles.imgproduct} />
                                    <h2 className={blogStyles.vcardtitle}>{title}</h2>
                                    <p className={blogStyles.vcardinfo}>{description}</p>
                                    <p className={blogStyles.preadmore}><Link to={link}>อ่านต่อ <Icon icon={arrowRight} /></Link></p>
                                    </Link>
                               </Col>
                        })}
                    </Row>
                    <Row>
                        <Col>
                            <ul
                              style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  alignItems: 'center',
                                  listStyle: 'none',
                                  paddingTop: 20,
                                  marginLeft: 0,
                                  justifyContent: 'center',
                                  }}
                            >
                                {!isFirst && (
                                    <Link to={prevPage} rel="prev">
                                    ← Previous
                                    </Link>
                                )}
                                {Array.from({ length: numPages }, (_, i) => (
                                    <li
                                    key={`pagination-number${i + 1}`}
                                    style={{
                                        margin: 0,
                                    }}
                                    >
                                    <Link
                                        to={`/blog/${i === 0 ? '' : i + 1}`}
                                        style={{
                                        padding: rhythm(1 / 4),
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        borderRadius: 6,
                                        textDecoration: 'none',
                                        color: i + 1 === currentPage ? '#ffffff' : '',
                                        background: i + 1 === currentPage ? '#050248' : '',
                                        }}
                                    >
                                        {i + 1}
                                    </Link>
                                    </li>
                                ))}
                                {!isLast && (
                                    <Link to={nextPage} rel="next">
                                    Next →
                                    </Link>
                                )}
                                </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Formfooter />
            <Footer />
      </Containers>
    )
  }
}
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { frontmatter: { key: { eq: "blog" }}}
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
        edges {
            node {
              id
              frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              fields {
                slug
              }
              excerpt
            }
          }
        }
      }
    `